import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import ContentImage from '../../../../components/ContentImage';
import Breadcrumb from '../../../../components/Breadcrumb';

const ConsultationAndCapacityBuilding = ({pageContext}) => (
	<Layout>
		<SEO title="Consultation and capacity building" />
		<Hero>
			<Breadcrumb crumbs={pageContext?.crumbs} />
			<h1>Consultation and capacity building</h1>
			<p></p>
		</Hero>
		<Block light>
			<Content>
				<Subnavigation>
					{/*<Anchors>*/}
					{/*	<a href={'#consultation-and-building'}>*/}
					{/*		Consultation and capacity building*/}
					{/*	</a>*/}
					{/*</Anchors>*/}
					<PersonBadge
						note={pageContext.service.contactNote}
						email={pageContext.service.contactPerson}
					/>
				</Subnavigation>
			</Content>
		</Block>
		<Block light>
			<Content>
				{/*<h2 id={'consultation-and-building'}>*/}
				{/*	Consultation and capacity building*/}
				{/*</h2>*/}
				<p>
					With more than 30 years of experience, Gisat experts share their
					knowledge providing consultation services to a wide community of users
					and clients and help them build their own technical or thematic
					capacity in environmental data use and analysis.
				</p>
				<p>
					Capacity-building activities have become an indispensable component of
					many projects and client-oriented applications. Physical on-site
					workshops or on-line webinars, both aim at improving clients’ capacity
					to understand, handle and analyse complex solutions based on Earth
					Observation satellite technology.
				</p>
				<p>
					We have a proven record in consultancy and provision of workshops in
					tailored formats delivered by our consultants and specialists to
					clients from governmental, regional or private institutions in
					Czechia, Myanmar, Georgia, Armenia, Bhutan and Vietnam, to European
					institutions, NGOs or international financial institutions such as
					World Bank or Asian Development Bank.
				</p>
				<p>
					Would you like to use Earth Observation or other geospatial data, but
					not sure where to start and which roadmap to follow? Feel free to
					contact us and our experts will prepare consultation meetings or
					capacity building programme tailored just to your needs.
				</p>
				{/*<ContentImage*/}
				{/*	fluid={null}*/}
				{/*	legend={`Fotky z */}
				{/*			Barmy*/}
				{/*			Gruzie/Armenie*/}
				{/*			Brazilie (GPSC)`}*/}
				{/*/>*/}
			</Content>
		</Block>
	</Layout>
);

ConsultationAndCapacityBuilding.propTypes = {
	pageContext: PropTypes.object.isRequired,
};

export default ConsultationAndCapacityBuilding;
